@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Pretendard';
    src: url('./assets/fonts/work-sans-v16-latin-regular.woff2') format('woff2');
    unicode-range: U+0030-0039, U+000-5FF;
    font-style: normal;
    font-weight: 400;
  }
  @font-face {
    font-family: 'Pretendard';
    src: url('./assets/fonts/work-sans-v16-latin-700.woff2') format('woff2');
    unicode-range: U+0030-0039, U+000-5FF;
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'Pretendard';
    src: url('./assets/fonts/work-sans-v16-latin-600.woff2') format('woff2');
    unicode-range: U+0030-0039, U+000-5FF;
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: 'Pretendard';
    src: url('./assets/fonts/work-sans-v16-latin-500.woff2') format('woff2');
    unicode-range: U+0030-0039, U+000-5FF;
    font-weight: 500;
    font-style: normal;
  }
}

@layer base {
  body,
  html {
    @apply font-pretendard text-gray-800;
  }
  button,
  input,
  textarea,
  select {
    @apply focus:outline-none;
  }
}

.swiper-pagination-bullet {
  opacity: 0.5;
  background-color: #ffffff;
}

.swiper-pagination-bullet-active {
  opacity: 1;
}

.crossed {
  background: linear-gradient(
    to top left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) calc(50% - 0.8px),
    rgba(209, 213, 219) 50%,
    rgba(0, 0, 0, 0) calc(50% + 0.8px),
    rgba(0, 0, 0, 0) 100%
  );
}

@layer components {
  .avatar {
    @apply inline-block object-cover rounded-full;
  }
  .avatar-default {
    @apply inline-block align-middle rounded-full overflow-hidden bg-gray-100;
  }
  .button {
    @apply px-4 h-12 font-normal;
  }
  .card {
    @apply px-6 py-8 w-full max-w-sm rounded-2xl shadow-lg flex flex-col bg-white;
  }
  .checkbox {
    @apply wh-4 rounded text-brand-1
           focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-1;
  }
  .dropdown {
    @apply relative w-max;
  }
  .dropdown-view {
    @apply absolute mt-2 w-max shadow-lg ring-1 ring-black ring-opacity-5 bg-white z-10
           focus:outline-none transition transform origin-top;
  }
  h1 {
    @apply text-36;
  }

  .label {
    @apply text-sm text-gray-800;
  }
  .label-col {
    @apply flex flex-col space-y-1;
  }
  .label-row {
    @apply flex items-center space-x-2;
  }
  .menu {
    @apply flex flex-col py-1;
  }
  .menu-item {
    @apply px-4 py-2 text-left text-sm text-gray-600 hover:bg-gray-100 hover:text-gray-800;
  }
  .pagination {
    @apply flex justify-between items-center px-6 py-3;
  }
  .pagination-label {
    @apply text-sm text-gray-700;
  }
  .pagination-nav {
    @apply flex shadow-sm -space-x-px;
  }
  .pagination-nav-button {
    @apply grid place-items-center p-2 min-w-10 border text-sm font-semibold;
  }

  .progress[value]::-webkit-progress-bar {
    @apply bg-gray-200 rounded-full overflow-hidden;
  }

  .progress[value] {
    appearance: none;
  }

  .progress[value]::-webkit-progress-value {
    @apply bg-green-500;
  }

  .radio {
    @apply wh-4 text-brand-1
           focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-1;
  }
  .select {
    @apply px-4 h-12 border border-gray-200 rounded-md placeholder-gray-400
           disabled:bg-gray-100 disabled:text-gray-400 focus:border-brand-1 focus:ring-0;
  }
  .sidebar {
    @apply flex flex-col w-64 border-r border-gray-200;
  }
  .sidebar-title {
    @apply flex items-center px-4 h-16 font-bold text-2xl text-gray-800;
  }
  .sidebar-menu {
    @apply flex-grow flex flex-col space-y-1 p-2 overflow-y-auto;
  }
  .sidebar-menu-item {
    @apply p-2 rounded-md text-sm font-semibold flex items-center;
  }
  .table {
    @apply divide-y divide-gray-200;
  }
  .table-head {
    @apply bg-gray-50;
  }
  .table-body {
    @apply divide-y divide-gray-200;
  }
  .table-th {
    @apply px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider;
  }
  .table-td {
    @apply px-6 py-4 max-w-xs text-left text-sm text-gray-500 truncate;
  }
  .textarea {
    @apply px-4 py-2 border border-gray-200 rounded-md placeholder-gray-400
           disabled:bg-gray-100 disabled:text-gray-400 focus:border-brand-1 focus:ring-0;
  }
  .textfield {
    @apply px-4 h-12 border border-gray-200  placeholder-gray-400
           disabled:bg-gray-100 disabled:text-gray-500 focus:border-brand-1 focus:ring-0;
  }

  .textfield::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .textfield::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .toggle {
    @apply relative inline-flex flex-shrink-0 w-11 h-6 border-2 border-transparent rounded-full
           cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-1
           transition-colors ease-in-out duration-200;
  }
  .toggle-slider {
    @apply pointer-events-none inline-block w-5 h-5 rounded-full bg-white shadow ring-0
           transition ease-in-out duration-200 transform;
  }
}
